<template>
  <b-popover
    ref="popover"
    :target="target"
    triggers="hover"
    placement="right"
    container="my-container"
  >
    <slot />
  </b-popover>
</template>

<script>
import { BPopover } from 'bootstrap-vue'

export default {
  components: {
    BPopover,
  },
  props: {
    target: {
      type: String,
      required: true,
      default: 'popover-alert',
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
