<template>
  <div
    class="list"
    style="height: inherit"
  >
    <!-- Prodcuts -->
    <section class="mt-2">
      <b-card
        class="mt-2"
        :title="$route.params.id"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="invoice-text">
            <h6 class="d-flex justify-content-center align-items-center">
              <span>代理点账单金额</span>
              <feather-icon
                id="popover-alert1"
                icon="AlertCircleIcon"
                size="26"
                class="alert-icon"
              />
            </h6>
            <div class="price">
              AU${{ total_agent_fee }}
            </div>
          </div>
          <div class="invoice-text">
            <h6 class="d-flex justify-content-center align-items-center">
              <span>客户实际支付费用</span>
              <feather-icon
                id="popover-alert2"
                icon="AlertCircleIcon"
                size="26"
                class="alert-icon"
              />
            </h6>
            <div class="price">
              AU${{ total_payment }}
            </div>
          </div>
          <div class="invoice-text">
            <h6>预估利润</h6>
            <div class="price">
              AU${{ total_profit }}
            </div>
          </div>
        </div>
      </b-card>
      <div class="d-flex justify-content-start mt-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="handleExport"
        >
          下载Excel
        </b-button>
      </div>
      <b-card class="mt-2">
        <div
          v-if="isLoading"
          class="d-flex justify-content-center align-items-center"
        >
          <b-spinner
            type="grow"
            variant="warning"
            class="mr-1"
          />
          <span class="loading-text">Loading...</span>
        </div>
        <!-- table -->
        <vue-good-table
          v-else
          :columns="columns"
          :rows="shipments"
          :fixed-header="true"
          max-height="600px"
          style-class="vgt-table"
          :rtl="direction"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
          }"
          :select-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageInfo.perPage
          }"
          @on-page-change="onPageChange"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'client_id'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.client_id }} {{ props.row.client_name }}</span>
            </span>

            <!-- Column: receiver -->
            <span
              v-else-if="props.column.field === 'receiver'"
              class="text-nowrap"
            >
              <span class="d-block">{{ props.row.recipient_name }}, {{ props.row.country }}</span>
            </span>

            <!-- Column: total_estimate_cost -->
            <span
              v-else-if="props.column.field === 'total_estimate_cost'"
              class="text-nowrap"
            >
              <span class="text-nowrap">AU${{ props.row.fee }}</span>
            </span>

            <!-- Column: shipment_status -->
            <span v-else-if="props.column.field === 'shipment_status'">
              <b-badge :variant="statusVariant(props.row.shipment_status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="action-btn"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                style="padding:5px"
                variant="flat-primary"
                @click="handleWatch(props.row.id)"
              >
                查看
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageInfo.perPage"
                  :options="['10','20','30','50']"
                  class="mx-1"
                  @change="handlePerPageChange"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ pageInfo.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="pageInfo.total"
                  :per-page="pageInfo.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </section>
    <AlertPopover :target="'popover-alert1'">
      <div class="mb-1">
        <span class="alert-text1">代理点账单运输费用:</span>
        <span class="alert-text2">AU${{ total_agent_freight }}</span>
      </div>
      <div>
        <span class="alert-text1">代理点账单其他费用:</span>
        <span class="alert-text2">AU${{ total_agent_other }}</span>
      </div>
    </AlertPopover>
    <AlertPopover :target="'popover-alert2'">
      <div class="mb-1">
        <span class="alert-text1">客户实际支付运输费用: </span>
        <span class="alert-text2">AU${{ total_payment_freight }}</span>
      </div>
      <div>
        <span class="alert-text1">客户实际支付其他费用: </span>
        <span class="alert-text2">AU${{ total_payment_other }}</span>
      </div>
    </AlertPopover>
  </div>
</template>
<script>
import {
  BBadge, BPagination, BFormSelect, BCard, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import AlertPopover from './components/alertPopover.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBadge,
    BPagination,
    BFormSelect,
    BCard,
    BButton,
    BSpinner,
    VueGoodTable,
    AlertPopover,
  },
  data() {
    return {
      filters: {
        total: 0,
      },
      sortBy: { text: '时间', value: '1' },
      sortByOptions: [
        { text: '时间', value: '1' },
        { text: '价格', value: '2' },
      ],
      sortByTable: { text: '变更为已支付', value: '1' },
      sortByTableOptions: [
        { text: '变更为已支付', value: '1' },
        { text: '价格', value: '2' },
      ],
      dir: false,
      columns: [
        {
          label: '客户ID',
          field: 'client_id',
        },
        {
          label: '运单ID',
          field: 'shipment_no',
        },
        {
          label: '收件人',
          field: 'receiver',
        },
        {
          label: '物流公司',
          field: 'courier_name',
        },
        {
          label: '运费',
          field: 'total_estimate_cost',
        },
        {
          label: '运单状态',
          field: 'shipment_status',
        },
        {
          label: '操作',
          field: 'action',
          sortable: false,
        },
      ],
      status: [{
        1: 'voided',
        2: 'shipped',
        3: 'pay_now',
        4: 'submitted',
      },
      {
        1: 'light-secondary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-info',
      }],
      pageInfo: {
        page: 1,
        perPage: 10,
        total: 1,
        keyword: '',
        status: '',
      },
      total_agent_fee: 0,
      total_payment: 0,
      total_profit: 0,
      total_payment_freight: 0,
      total_payment_other: 0,
      total_agent_freight: 0,
      total_agent_other: 0,
      shipments: [],
      isLoading: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        voided: 'light-secondary',
        shipped: 'light-success',
        pay_now: 'light-danger',
        submitted: 'light-warning',
        packing: 'light-primary',
        paid_agent: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.statisticsDate = localStorage.getItem('statisticsDate')
      ? JSON.parse(localStorage.getItem('statisticsDate'))
      : {}
    this.handleStatisticAgentShipment()
  },
  methods: {
    handleWatch(id) {
      this.$router.push(`/shipment/detail/${id}`)
    },
    handleExport() {
      this.$store.dispatch('shipment/handleExport', {
        week_start: this.statisticsDate.week_start,
        week_end: this.statisticsDate.week_end,
      })
    },
    async handleStatisticAgentShipment() {
      try {
        this.isLoading = true
        const { data } = await this.$api.statisticAgentShipment({
          start_time: this.statisticsDate.week_start,
          end_time: this.statisticsDate.week_end,
        })
        this.isLoading = false
        this.total_agent_fee = data.total_agent_fee
        this.total_payment = data.total_payment
        this.total_profit = data.total_profit
        this.shipments = data.shipments
        this.total_payment_freight = data.total_payment_freight
        this.total_payment_other = data.total_payment_other
        this.total_agent_freight = data.total_agent_freight
        this.total_agent_other = data.total_agent_other
      } catch (error) {
        console.log('error', error)
      }
    },
    handlePerPageChange(val) {
      this.pageInfo.perPage = val
    },
    onPageChange(params) {
      this.pageInfo.page = params.currentPage
    },
    handleSearchEnter() {
      console.log('search')
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
.card-wrap{
  padding: 140px 0;
}
.number-box{
  margin: 200px;
}
.show-text{
  display:block;
  word-break: keep-all;
  margin-right:10px;
}
.list ::v-deep table.vgt-table td{
  vertical-align: middle;
}
.list ::v-deep ul.dropdown-menu.dropdown-menu-right.show{
  z-index:11;
}
.blue{
  color: #7367f0;
  text-decoration: underline;
  cursor: pointer;
}
.invoice-text{
  width: 33%;
  text-align: center;
  h6{
    font-size: 26px;
  }
  font-size: 26px;
}
.price{
  color: #28c76f;
  font-size: 30px;
}
.action-btn{
  display:flex;
  min-width: 60px;
}
.alert-icon{
  margin-left: 5px;
  color: #FFAF2A;
  cursor: pointer;
}
.alert-text1{
  font-size: 18px;
  display: block;
}
.alert-text2{
  font-size: 18px;
  color: #FFAF2A;
}
.loading-text{
  font-size: 26px;
}
</style>
